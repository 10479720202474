<template>
  <div class="news-link" v-if="items.length > 0">
    <v-menu offset-y max-width="400">
      <template #activator="menuActivator">
        <v-tooltip bottom>
          <template #activator="tooltipActivator">
            <v-btn class="news-icon" v-bind="menuActivator.attrs" v-on="{ ...menuActivator.on, ...tooltipActivator.on }" @click="lastReadNews = new Date().toISOString()" text>
              <v-badge color="red" dot overlap :value="isRead !== true">
                <v-icon size="20" color="primary">mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>トコトンからのお知らせ</span>
        </v-tooltip>
      </template>
      <v-list dense>
        <template v-for="(item, index) in items">
          <v-list-item :key="`news-1-${index}`" :href="`${item.link}?utm_source=app&utm_medium=referral`" target="_blank" rel="noopener">
            <v-list-item-content>
              <v-list-item-subtitle class="mb-2">
                {{ item.pubDate | moment("M月D日") }}
                <v-chip class="ml-1" x-small style="vertical-align: bottom; pointer-events: none;">{{ item.category }}</v-chip>
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ item.title }}
                <v-icon small style="vertical-align: bottom;">mdi-open-in-new</v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < items.length - 1" :key="`news-2-${index}`"></v-divider>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'NewsTicker',
  data: () => ({
    items: [],
    lastReadNews: null
  }),
  created: function () {
    this.readDataFromXML()
    this.lastReadNews = localStorage.getItem('lastReadNews')
  },
  computed: {
    isRead: function () {
      const lastReadNews = this.lastReadNews
      if (lastReadNews === null) {
        return false
      }
      const lastReadNewsDate = new Date(lastReadNews)
      const lastNewsDate = new Date(this.items[0].pubDate)
      return lastReadNewsDate >= lastNewsDate
    }
  },
  watch: {
    lastReadNews: function (val) {
      localStorage.setItem('lastReadNews', val)
    }
  },
  methods: {
    readDataFromXML: function () {
      const _self = this
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/biz/news`)
        .then(res => {
          _self.items = res.data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.news-link {
  height: inherit;
  .news-icon {
    height: inherit;
  }
}
</style>
